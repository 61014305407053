import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { ApiService } from '../service';

export const Api = {
  init(baseURL: string) {
    axios.defaults.baseURL = baseURL;
  },

  // HTTP GET
  get: axios.get,

  // HTTP POST
  post: axios.post,

  // HTTP PUT
  put: axios.put,

  // HTTP DELETE
  delete: axios.delete,
} as ApiService;

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error: AxiosError) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Response interceptor
axios.interceptors.response.use(
  (response: AxiosResponse) => {
    // Do something with response data
    return response;
  },
  (error: AxiosError) => {
    // Do something with response error
    return Promise.reject(error);
  },
);
