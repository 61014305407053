/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TIMELINE_ACTION,
  DOCUMENT_CHANGED,
  DOCUMENT_CLEARED,
  DOCUMENT_STATE_CLEAR,
  DOCUMENT_STATE_SET,
  TIMELINE_ACTION_STATE_ADD,
} from './types';

import { ParentState } from './model';

import Vue from 'vue';

export const ParentStore = {
  name: 'parent',
  namespaced: true,
  state: {
    action_history: [],
    document: null,
    user: null,
  } as ParentState,
  mutations: {
    [DOCUMENT_STATE_SET](state: ParentState, document: any) {
      Vue.set(state, 'document', document);
    },
    [DOCUMENT_STATE_CLEAR](state: ParentState) {
      Vue.set(state, 'document', null);
    },
    [TIMELINE_ACTION_STATE_ADD](state: ParentState, action: any) {
      Vue.set(
        state,
        'action_history',
        state.action_history.splice(0, 0, action),
      );
    },
  },
  actions: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [TIMELINE_ACTION]({ commit, state, dispatch }: any, { action }: any) {
      console.log(window.location.host, TIMELINE_ACTION, action);
      commit(TIMELINE_ACTION_STATE_ADD, action);
      // state.some_message = value;
      // dispatch(`JAMES_TESTING`, { test: 2 });
    },
    [DOCUMENT_CHANGED]({ state, commit }: any, { document }: any) {
      console.log(window.location.host, DOCUMENT_CHANGED, state, document);
      commit(DOCUMENT_STATE_SET, document);
    },
    [DOCUMENT_CLEARED]({ state, commit }: any) {
      console.log(DOCUMENT_CLEARED, state);
      commit(DOCUMENT_STATE_CLEAR);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ['ACTION_RESPONSE']({ state }: any) {
      // Blank
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ['DOCUMENT_ENRICH']({ state }: any) {
      // Blank
    },
  },
  getters: {
    document: (state: ParentState) => {
      return state.document;
    },
    user: (state: ParentState) => {
      return state.user;
    },
  },
};
