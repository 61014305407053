import { Module } from 'vuex';

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import { ReportState } from '@/store';

export * from './types';

export const report: Module<ReportState, unknown> = {
  namespaced: true,
  state: {
    reports: {},
  } as ReportState,
  actions,
  mutations,
  getters,
};

export type ReportGetters = typeof getters;
export type ReportActions = typeof actions;
export type ReportMutations = typeof mutations;
