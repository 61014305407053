/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosPromise } from 'axios';

import { Api } from '@/service/api.service';

const resource = '/schedule';

export const Schedule = {
  /**
   * Retry a failed schedule job
   *
   * @param name
   * @param runTime
   */
  retry(name: string, runTime: string): AxiosPromise<any> {
    return Api.post(`${resource}/${name}/${runTime}/retry`);
  },

  /**
   * Ignore a failed schedule job
   *
   * @param name
   * @param runTime
   */
  ignore(name: string, runTime: string): AxiosPromise<any> {
    return Api.post(`${resource}/${name}/${runTime}/ignore`);
  },
};
