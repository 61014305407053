import { Module } from 'vuex';

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import { ImportJobState } from '@/store';

export * from './types';

export const importJob: Module<ImportJobState, unknown> = {
  namespaced: true,
  state: {} as ImportJobState,
  actions,
  mutations,
  getters,
};

export type ImportJobGetters = typeof getters;
export type ImportJobActions = typeof actions;
export type ImportJobMutations = typeof mutations;
