import { AxiosResponse } from 'axios';

import { Document, DocumentQuery } from '../../service';
import { DocumentContext } from '../..//store';

// Load a single document by document id
export const documentByIdLoad = async (
  ctx: DocumentContext,
  documentId: string,
): Promise<void> => {
  await Document.getById(documentId).then((response: AxiosResponse) => {
    ctx.commit('documentsByIdSet', response.data);
  });
};

// Get a list of documents by document type
export const documentsByTypeLoad = async (
  ctx: DocumentContext,
  query: DocumentQuery,
): Promise<void> => {
  await Document.getByType(query.apiQuery).then((response: AxiosResponse) => {
    ctx.commit('documentsByTypeSet', {
      documentType: query.apiQuery.type,
      data: response.data,
      page: query.page,
    });
  });
};

// Get a list of document events by document id
export const documentEventsLoad = async (
  ctx: DocumentContext,
  documentId: string,
): Promise<void> => {
  await Document.getEvents(documentId).then((response: AxiosResponse) => {
    ctx.commit('documentEventsSet', {
      documentId,
      events: response.data.data,
    });
  });
};
