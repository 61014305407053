/* eslint-disable @typescript-eslint/no-explicit-any */
import { Api } from '@/service/api.service';
import { ReportOverrideAPIRequest } from './types';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import querystring from 'querystring';

const resource = '/reports';

export const Report = {
  get(reportId: string): AxiosPromise<any> {
    return Api.get(`${resource}/${reportId}`);
  },

  /**
   * Download a report xml file
   *
   * @param reportId
   */
  download(reportId: string): AxiosPromise<any> {
    return Api.get(`${resource}/${reportId}/download`, {
      responseType: 'arraybuffer',
    });
  },

  stats(query: querystring.ParsedUrlQueryInput): AxiosPromise<any> {
    return Api.post(`${resource}/stats?${querystring.encode(query)}`);
  },

  /**
   * Override the generated report file with a new one
   *
   * @param reportId
   * @param report
   */
  override(
    reportId: string,
    { report }: ReportOverrideAPIRequest,
    onUploadProgress?: (p: ProgressEvent) => void,
  ): AxiosPromise<any> {
    const formData = new FormData();
    formData.append('file', report);

    return Api.post(`${resource}/${reportId}/override`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    } as AxiosRequestConfig);
  },
};
