import { DataExport, ExportResponse } from '@/service';
import {
  DataExportContext,
  ReportExportRequest,
  ReportItemExportRequest,
  TransactionExportRequest,
} from './types';

/**
 * Export a set of report items
 *
 * @param request
 */
export const reportItemExport = async (
  ctx: DataExportContext,
  request: ReportItemExportRequest,
): Promise<ExportResponse> => {
  const response = await DataExport.reportItem(request);
  return response.data;
};

/**
 * Export a set of reports
 *
 * @param request
 */
export const reportExport = async (
  ctx: DataExportContext,
  request: ReportExportRequest,
): Promise<ExportResponse> => {
  const response = await DataExport.report(request);
  return response.data;
};

/**
 * Export a set of transactions
 *
 * @param request
 */
export const transactionExport = async (
  ctx: DataExportContext,
  request: TransactionExportRequest,
): Promise<ExportResponse> => {
  const response = await DataExport.transaction(request);
  return response.data;
};
