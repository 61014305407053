/* eslint-disable @typescript-eslint/no-explicit-any */
import { Api } from '@/service/api.service';
import { ImportJobCreateAPIRequest } from './types';
import { AxiosPromise, AxiosRequestConfig } from 'axios';

const resource = '/import-jobs';

export const ImportJob = {
  /**
   * Upload a new transaction file
   *
   */
  create(
    { file, schema }: ImportJobCreateAPIRequest,
    onUploadProgress?: (p: ProgressEvent) => void,
  ): AxiosPromise<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('schema', schema);

    return Api.post(`${resource}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    } as AxiosRequestConfig);
  },
};
