import { Store } from 'vuex';
import { ScheduleRetryRequest, ScheduleIgnoreRequest } from '../schedule';
import { ReportItemRulesRetryRequest } from '../report-item';
import {
  ReportItemExportRequest,
  ReportExportRequest,
  TransactionExportRequest,
} from '../data-export';
import { SummarySystemRequest, SummaryReportItemPayload } from '@/service';
import { OverlayVuexPlugin } from 'overlay-plugin-sdk';
import { TransactionsGetRequest } from '../transaction';

export const overlay = OverlayVuexPlugin((store: Store<unknown>) => ({
  reportItemSummary: async (payload: SummaryReportItemPayload) => {
    return store.dispatch('dashboard/reportItemSummaryGet', payload);
  },

  reportItemSummaryDownload: async (payload: SummaryReportItemPayload) => {
    return store.dispatch('dashboard/reportItemSummaryDownload', payload);
  },

  systemSummary: async (query: SummarySystemRequest) => {
    return store.dispatch('dashboard/systemSummaryGet', query);
  },

  /**
   * Retry a failed schedule
   */
  scheduleRetry: async (payload: ScheduleRetryRequest) => {
    return store.dispatch('schedule/scheduleRetry', payload);
  },

  /**
   * Ignore a failed schedule
   */
  scheduleIgnore: async (payload: ScheduleIgnoreRequest) => {
    return store.dispatch('schedule/scheduleIgnore', payload);
  },

  /**
   * Export a set of report items
   */
  reportItemExport: async (payload: ReportItemExportRequest) => {
    return store.dispatch('dataExport/reportItemExport', payload);
  },

  /**
   * Export a set of reports
   */
  reportExport: async (payload: ReportExportRequest) => {
    return store.dispatch('dataExport/reportExport', payload);
  },

  /**
   * Export a set of transactions
   */
  transactionExport: async (payload: TransactionExportRequest) => {
    return store.dispatch('dataExport/transactionExport', payload);
  },

  /**
   * Export a set of reports
   */
  reportStats: async (payload: Record<string, string>) => {
    return store.dispatch('report/reportStats', payload);
  },

  /**
   * Re-run a transaction through the reporting rules
   */
  reportItemRulesRetry: async (payload: ReportItemRulesRetryRequest) => {
    return store.dispatch('reportItem/reportItemRulesRetry', payload);
  },

  envNameSet: async (payload: string) => {
    return store.dispatch('config/envNameSet', payload);
  },

  /**
   * Export a set of reports
   */
  transactionsGet: async (payload: TransactionsGetRequest) => {
    if (payload.ids.length) {
      return store.dispatch('transaction/transactionsGet', payload);
    }
  },
}));
