/* eslint-disable @typescript-eslint/no-explicit-any */
import { Api } from '@/service/api.service';

import {
  ReportItemEditApiRequest,
  ReportItemEditApiApprove,
  ReportItemEditApiReject,
  ExceptionIgnoreApiRequest,
  ExceptionIgnoreApiApprove,
  ExceptionIgnoreApiReject,
  NotReportableApiRequest,
  NotReportableApiApprove,
  NotReportableApiReject,
  ReportItemCheckResponse,
  ReportItemCheckRequest,
  ReportEntryEditApiRequest,
  ReportEntryEditApiApprove,
  ReportEntryEditApiReject,
  ReportItemRulesRetry,
} from './types';
import { AxiosProgressEvent, AxiosPromise } from 'axios';

const resource = '/report-items';

export const ReportItem = {
  // get available editors and decisions for a report item
  get(reportItemId: string): AxiosPromise<any> {
    return Api.get(`${resource}/${reportItemId}`);
  },

  /* --- REPORT ITEM EDIT --- */

  // Request edit
  editRequest(
    reportItemId: string,
    payload: ReportItemEditApiRequest,
  ): AxiosPromise<any> {
    return Api.post(`${resource}/${reportItemId}/edits`, payload);
  },

  // Approve edit
  editApprove(
    reportItemId: string,
    editId: string,
    payload: ReportItemEditApiApprove,
  ): AxiosPromise<any> {
    return Api.post(
      `${resource}/${reportItemId}/edits/${editId}/approve`,
      payload,
    );
  },

  // Reject edit
  editReject(
    reportItemId: string,
    editId: string,
    payload: ReportItemEditApiReject,
  ): AxiosPromise<any> {
    return Api.post(
      `${resource}/${reportItemId}/edits/${editId}/reject`,
      payload,
    );
  },

  /* --- REPORT ENTRY EDIT  --- */

  // Request edit
  reportEntryEditRequest(
    reportItemId: string,
    payload: ReportEntryEditApiRequest,
  ): AxiosPromise<any> {
    return Api.post(`${resource}/${reportItemId}/original/edits`, payload);
  },

  // Approve edit
  reportEntryEditApprove(
    reportItemId: string,
    editId: string,
    payload: ReportEntryEditApiApprove,
  ): AxiosPromise<any> {
    return Api.post(
      `${resource}/${reportItemId}/original/edits/${editId}/approve`,
      payload,
    );
  },

  // Reject edit
  reportEntryEditReject(
    reportItemId: string,
    editId: string,
    payload: ReportEntryEditApiReject,
  ): AxiosPromise<any> {
    return Api.post(
      `${resource}/${reportItemId}/original/edits/${editId}/reject`,
      payload,
    );
  },

  /* --- EXCEPTION IGNORE --- */

  // Request exception ignore
  exceptionIgnoreRequest(
    reportItemId: string,
    exceptionId: string,
    payload: ExceptionIgnoreApiRequest,
  ): AxiosPromise<any> {
    return Api.post(
      `${resource}/${reportItemId}/exception/${exceptionId}/ignore`,
      payload,
    );
  },

  // Approve exception ignore
  exceptionIgnoreApprove(
    reportItemId: string,
    exceptionId: string,
    payload: ExceptionIgnoreApiApprove,
  ): AxiosPromise<any> {
    return Api.post(
      `${resource}/${reportItemId}/exception/${exceptionId}/ignore/approve`,
      payload,
    );
  },

  // Reject exception ignore
  exceptionIgnoreReject(
    reportItemId: string,
    exceptionId: string,
    payload: ExceptionIgnoreApiReject,
  ): AxiosPromise<any> {
    return Api.post(
      `${resource}/${reportItemId}/exception/${exceptionId}/ignore/reject`,
      payload,
    );
  },

  /* --- REPORT ITEM NOT REPORTABLE --- */

  // Request not reportable
  notReportableRequest(
    reportItemId: string,
    { justification, file }: NotReportableApiRequest,
    onUploadProgress?: (p: AxiosProgressEvent) => void,
  ): AxiosPromise<any> {
    const formData = new FormData();
    formData.append('justification', justification);
    formData.append('file', file);

    return Api.post(`${resource}/${reportItemId}/not-reportable`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  },

  // Not reportable approve
  notReportableApprove(
    reportItemId: string,
    payload: NotReportableApiApprove,
  ): AxiosPromise<any> {
    return Api.post(
      `${resource}/${reportItemId}/not-reportable/approve`,
      payload,
    );
  },

  // Not reportable reject
  notReportableReject(
    reportItemId: string,
    payload: NotReportableApiReject,
  ): AxiosPromise<any> {
    return Api.post(
      `${resource}/${reportItemId}/not-reportable/reject`,
      payload,
    );
  },

  // Download supporting documentation
  notReportableDownload(reportItemId: string): AxiosPromise<any> {
    return Api.get(`${resource}/${reportItemId}/not-reportable/download`, {
      responseType: 'arraybuffer',
    });
  },

  /* --- EDIT CHECK --- */

  // run a rule set against a report item with an array of edits
  check(
    reportItemId: string,
    payload: ReportItemCheckRequest,
  ): AxiosPromise<ReportItemCheckResponse> {
    return Api.post(`${resource}/${reportItemId}/check`, payload);
  },

  /* --- RULE SET RETRY --- */

  // Re-run a transaction through the reporting rules
  rulesRetry(
    reportItemId: string,
    payload: ReportItemRulesRetry,
  ): AxiosPromise<ReportItemCheckResponse> {
    return Api.post(`${resource}/${reportItemId}/ruleset-retry`, payload);
  },
};
