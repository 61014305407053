import { ReportState, ReportModel } from '@/store';

export const reports = (state: ReportState): Record<string, ReportModel> => {
  return state.reports;
};

export const reportById =
  (state: ReportState) =>
  (id: string): ReportModel => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.reports.hasOwnProperty(id)) {
      return state.reports[id];
    }
    return {
      allow_download: false,
      allow_override: false,
    };
  };
