import { Module } from 'vuex';

import * as actions from './actions';
export * from './types';

export const dataExport: Module<unknown, unknown> = {
  namespaced: true,
  actions,
};

export type DataExportActions = typeof actions;
