import {
  Dashboard,
  SummaryReportItemPayload,
  SummarySystemPayload,
} from '@/service';
import { DashboardContext, ReportItemSummary, SystemSummary } from './types';
import { downloadFile, extractFileName } from 'i8-ui';

/**
 * Load a report item summary for a single date
 *
 * @param ctx DashboardContext
 * @param date
 */
export const reportItemSummaryGet = async (
  ctx: DashboardContext,
  { query, request }: SummaryReportItemPayload,
): Promise<ReportItemSummary | undefined> => {
  if (!query.from || !query.to) {
    console.warn(
      'unable to load report item summary, from date and to date are both required',
      query,
    );
    return;
  }

  const response = await Dashboard.summaryReportItemGet(query, request);
  const reportItemSummary: ReportItemSummary = response.data;

  // ctx.commit('reportItemSummarySet', { reportItemSummary, date: query.date });

  return reportItemSummary;
};

/**
 * Download report item summary file
 *
 * @param ctx DashboardContext
 * @param date
 */
export const reportItemSummaryDownload = async (
  ctx: DashboardContext,
  { query, request }: SummaryReportItemPayload,
): Promise<ReportItemSummary | undefined> => {
  if (!query.from || !query.to) {
    console.warn(
      'unable to download file, from date and to date are both required',
      query,
    );
    return;
  }

  const response = await Dashboard.summaryReportItemDownload(query, request);

  const filename = extractFileName(response);
  const mime = response.headers['content-type'];
  downloadFile(response.data, filename, mime);
};

/**
 * Load a system summary for a single date
 *
 * @param ctx DashboardContext
 * @param date
 */
export const systemSummaryGet = async (
  ctx: DashboardContext,
  { query, request }: SummarySystemPayload,
): Promise<SystemSummary | undefined> => {
  const response = await Dashboard.summarySystemGet(query, request);
  const systemSummary: SystemSummary = response.data;

  // ctx.commit('systemSummarySet', { systemSummary, date: query.date });
  return systemSummary;
};
