/* eslint-disable @typescript-eslint/no-namespace, no-inner-declarations, @typescript-eslint/no-use-before-define */
import { Postmate } from '../lib/postmate';
import { Challenge, verify, ChallengeEvents, newChallenge } from './challenge';
import { ChildAPI, PayloadFor } from './types';
import isFunction from 'lodash/isFunction';

export namespace Plugin {
  /**
   * API for a plugin to communicate with Overlay
   */
  export type OverlayApi = ChildAPI;

  // eslint-disable-next-line @typescript-eslint/ban-types
  export async function connectToOverlay<Model extends object>(
    model?: Model,
  ): Promise<OverlayApi | void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<OverlayApi | void>(async (resolve, reject) => {
      function onOverlayChallenge(c: Challenge): void {
        if (!verify(c)) {
          const err = new Error('failed to verify Overlay challenge');
          overlay.emit(ChallengeEvents.Failure, err);
          return reject(err);
        }

        overlay.emit(ChallengeEvents.Success);
        resolve();
      }

      interface DispatchRequest<K extends keyof Model> {
        id: string;
        key: K;
        payload: PayloadFor<Model[K]>;
      }

      async function dispatch<K extends keyof Model>({
        id,
        key,
        payload,
      }: DispatchRequest<K>): Promise<void> {
        const fn = model && model[key];

        if (fn === undefined) {
          return overlay.emit(
            id,
            new Error(`action '${String(key)}' not found in plugin model`),
          );
        }

        // this function is needed for the rollup typescript compiler to
        // know that fn is a function. the lodash type isn't being applied
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isFunc = (f: any): f is (x: any) => any => isFunction(f);

        if (!isFunc(fn)) {
          return overlay.emit(
            id,
            new Error(`action '${String(key)}' is not a function`),
          );
        }

        try {
          // we need to await here so we don't try and send an unresolved
          // promise over the iframe
          // FIXME: fn typing
          const result = await fn(payload);
          return overlay.emit(id, result);
        } catch (err) {
          return overlay.emit(id, err);
        }
      }

      const overlay: ChildAPI = await new Postmate.Model({
        ...model,
        dispatch,
        [ChallengeEvents.OverlayChallenge]: onOverlayChallenge,
      });

      overlay.emit(ChallengeEvents.PluginChallenge, newChallenge());
    });
  }
}
