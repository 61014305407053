import Vue from 'vue';
import { ReportItemState } from '@/store';

export const reportItemSet = (
  state: ReportItemState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  reportItem: any,
): void => {
  Vue.set(state.reportItems, reportItem.documentId, reportItem.payload);
};
