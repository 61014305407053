// Fired when a user interacts with a timeline action button
export const TIMELINE_ACTION = 'TIMELINE_ACTION';
// Fired when a new document is selected
export const DOCUMENT_CHANGED = 'DOCUMENT_CHANGED';
// Fired when all document contexts are closed
export const DOCUMENT_CLEARED = 'DOCUMENT_CLEARED';
// Sent on startup to challenge the system to provide a
// response which complies with the challenge. This can
// be fired from time to time to renew sessions. Or
// when a user's permissions have been changed.
export const USER_CHALLENGE = 'USER_CHALLENGE';
// Sent when a user logs out or is expired, instructing
// the client app to cleanup.
export const USER_EXPIRE = 'USER_EXPIRE';
// Check the frame is still running and working.
export const APP_HEALTHCHECK = 'APP_HEALTHCHECK';

export const DOCUMENT_STATE_SET = 'DOCUMENT_STATE_SET';
export const DOCUMENT_STATE_CLEAR = 'DOCUMENT_STATE_CLEAR';
export const USER_STATE_SET = 'USER_STATE_SET';
export const USER_STATE_CLEAR = 'USER_STATE_CLEAR';

export const TIMELINE_ACTION_STATE_ADD = 'TIMELINE_ACTION_STATE_ADD';
