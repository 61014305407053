import { DocumentState, PagedDocuments, DocumentEvent } from '../..//store';
import Vue from 'vue';

// Store a document by id
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const documentsByIdSet = (state: DocumentState, document: any): void => {
  Vue.set(state.documentsById, document.id, document);
};

// Store a list of documents by type
export const documentsByTypeSet = (
  state: DocumentState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: { documentType: string; data: any; page: number },
): void => {
  const currentPage = state.documentsByType[payload.documentType];

  const documents = {
    documents: payload.data.data,
    cursors: currentPage ? currentPage.cursors : [],
  } as PagedDocuments;

  documents.cursors[payload.page + 1] = payload.data.next;

  Vue.set(state.documentsByType, payload.documentType, documents);
};

// Store a list of documents by type
export const documentEventsSet = (
  state: DocumentState,
  { documentId, events }: { documentId: string; events: DocumentEvent[] },
): void => {
  Vue.set(state.documentEvents, documentId, events);
};
