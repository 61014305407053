import { Module } from 'vuex';

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import { DashboardState } from './types';

export * from './types';

export const dashboard: Module<DashboardState, unknown> = {
  namespaced: true,
  state: {
    reportItemSummary: {},
    systemSummary: {},
  } as DashboardState,
  actions,
  mutations,
  getters,
};

export type DashboardGetters = typeof getters;
export type DashboardActions = typeof actions;
export type DashboardMutations = typeof mutations;
