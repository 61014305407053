import Vue from 'vue';
import App from './app.vue';
import { router } from '@/router';
import { store } from '@/store';

import '@/style/main.scss';
import { Api } from '@/service';
import { Api as SDKApi } from 'overlay-plugin-sdk';

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render: (h: any) => h(App),
});

app.$mount('#app');

// Set the base URL of the API
const rootApi = process.env.VUE_APP_ROOT_API;

if (rootApi) {
  SDKApi.init(rootApi);
  Api.init(rootApi);
} else {
  console.error('API URL not found in .env');
}
