import { OverlayContext } from '../../store';
import { OPluginContext } from './types';

export const pluginContextUpdate = async (
  ctx: OverlayContext,
  context: OPluginContext,
): Promise<void> => {
  ctx.commit('pluginContextSet', context);
};

export const userSessionSet = async (
  ctx: OverlayContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  userSession: any,
): Promise<void> => {
  ctx.commit('userSessionSet', userSession);
};
