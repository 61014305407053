import { Module } from 'vuex';

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import { ReportItemState } from '@/store';

export * from './types';

export const reportItem: Module<ReportItemState, unknown> = {
  namespaced: true,
  state: {
    reportItems: {},
  } as ReportItemState,
  actions,
  mutations,
  getters,
};

export type ReportItemGetters = typeof getters;
export type ReportItemActions = typeof actions;
export type ReportItemMutations = typeof mutations;
