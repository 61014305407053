import { Transaction } from '@/service';
import {
  TransactionContext,
  TransactionsGetRequest,
  TransactionsGetResponse,
} from './types';

/**
 * Load a list of transactions
 */
export const transactionsGet = async (
  ctx: TransactionContext,
  request: TransactionsGetRequest,
): Promise<TransactionsGetResponse> => {
  const response = await Transaction.transactionsGet(request);
  return response.data;
};
