import axios, { AxiosResponse, AxiosError } from 'axios';
import { ApiService } from '@/service';

export const Api = {
  init(baseURL: string | undefined) {
    axios.defaults.baseURL = baseURL;
  },

  authTokenSet(token: string) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  },

  // HTTP GET
  get: axios.get,

  // HTTP POST
  post: axios.post,

  // HTTP PUT
  put: axios.put,

  // HTTP DELETE
  delete: axios.delete,
} as ApiService;

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error: AxiosError) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Response interceptor
axios.interceptors.response.use(
  (response: AxiosResponse) => {
    // Do something with response data
    return response;
  },
  (error: AxiosError) => {
    // Do something with response error
    const isUnauthorized = error.response && error.response.status === 401;
    if (isUnauthorized) {
      window.parent.postMessage(
        { status: error.response?.status, data: error.response?.data },
        '*',
      );
    }
    return Promise.reject(error);
  },
);
