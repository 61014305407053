import sha256 from 'crypto-js/sha256';

export interface Challenge {
  message: string;
  digest: string;
}

/**
 * Create a new challenge
 */
export function newChallenge(): Challenge {
  // TODO: make this random, move the challenge generation to the server
  const message = 'overlay_handshake';
  const digest = sha256(message).toString();
  return { message, digest };
}

/**
 * Verify a challenge
 * @param challenge The challenge to verify
 */
export function verify(challenge: Challenge): boolean {
  // TODO: Move the challenge verification to the server
  return sha256(challenge.message).toString() === challenge.digest;
}

export const enum ChallengeEvents {
  /**
   * The plugin has sent a challenge to Overlay
   */
  PluginChallenge = 'plugin.challenge',
  /**
   * Overlay is replying with a challenge to the plugin
   */
  OverlayChallenge = 'overlay.challenge',
  /**
   * Both challenges were verified successfully
   */
  Success = 'challenge.success',
  /**
   * A challenge failed, likely because it didn't pass verification
   */
  Failure = 'challenge.failure',
}
