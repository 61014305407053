/* eslint-disable @typescript-eslint/no-namespace, no-inner-declarations, @typescript-eslint/no-use-before-define */
import {
  PluginConnectionOptions,
  ParentAPI,
  Dispatch,
  DispatchOptions,
  ReturnTypeFor,
} from './types';
import { Postmate } from '../lib/postmate';
import { Challenge, verify, newChallenge, ChallengeEvents } from './challenge';
import isError from 'lodash/isError';
import defaults from 'lodash/defaults';
import pick from 'lodash/pick';

export namespace Overlay {
  /**
   * API for Overlay to communicate with a plugin
   */
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
  export interface PluginApi<Model extends object = any> {
    /**
     * Call a function exposed by the plugin and wait for it to return a result
     */
    dispatch: Dispatch<Model>;
    /**
     * Removes the iFrame element and destroys any plugin event listeners
     */
    destroy: () => void;
  }

  // the number of dispatch jobs for this connection
  let dispatchCount = 0;

  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
  export async function connectToPlugin<Model extends object = any>(
    connectionOptions: PluginConnectionOptions,
  ): Promise<PluginApi<Model>> {
    const plugin: ParentAPI = await new Postmate(connectionOptions);

    await new Promise<void>((resolve, reject) => {
      plugin.on(ChallengeEvents.PluginChallenge, (c: Challenge) => {
        if (!verify(c)) {
          const err = new Error('failed to verify plugin challenge');
          plugin.call(ChallengeEvents.Failure, err);
          return reject(err);
        }

        plugin.call(ChallengeEvents.OverlayChallenge, newChallenge());
      });

      plugin.on(ChallengeEvents.Success, resolve);

      plugin.on(ChallengeEvents.Failure, reject);
    });

    const dispatch: Dispatch<Model> = (key, payload, dispatchOptions) => {
      // create the options for this job by merging default, global and instance
      const jobOptions = mergeDispatchOptions(dispatchOptions);

      // unique id for this dispatch job
      const id = (dispatchCount++).toString();

      // dispatch job to the plugin
      const dispatchJob = new Promise<ReturnTypeFor<Model>>(
        (resolve, reject) => {
          plugin.on(id, (result) => {
            return isError(result) ? reject(result) : resolve(result);
          });

          plugin.call('dispatch', { id, key, payload });
        },
      );

      const dispatchTimeout = new Promise<Error>((_, reject) => {
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          reject(
            new Error(`dispatch timeout in ${jobOptions.dispatchTimeout}ms`),
          );
        }, jobOptions.dispatchTimeout);
      });

      return Promise.race([dispatchJob, dispatchTimeout]);
    };

    const mergeDispatchOptions = (
      jobOptions?: DispatchOptions,
    ): DispatchOptions => {
      // default dispatch options
      const defaultOptions = { dispatchTimeout: 15000 }; // 15 seconds

      // dispatch options that have been set during initiation
      const globalOptions = pick(
        connectionOptions,
        Object.keys(defaultOptions),
      ) as DispatchOptions;

      return defaults({}, jobOptions, globalOptions, defaultOptions);
    };

    return {
      destroy: plugin.destroy.bind(plugin),
      dispatch,
    };
  }
}
