import { AxiosPromise } from 'axios';
import { Api } from '@/service/api.service';

import { Countries, Currencies } from './types';

const resource = '/i18n';

export const I18n = {
  countriesGet(): AxiosPromise<Countries> {
    return Api.get(`${resource}/countries`);
  },

  currenciesGet(): AxiosPromise<Currencies> {
    return Api.get(`${resource}/currencies`);
  },
};
