import { AxiosPromise } from 'axios';

import { Api } from '@/service/api.service';
import { TransactionsGetRequest, TransactionsGetResponse } from './types';

const resource = '/transactions';

export const Transaction = {
  transactionsGet(
    request: TransactionsGetRequest,
  ): AxiosPromise<TransactionsGetResponse> {
    return Api.post(`${resource}`, request);
  },
};
