import get from 'lodash/get';
import filter from 'lodash/filter';
import {
  ReportItemState,
  ReportItemDecision,
  OverlayReportItem,
} from '@/store';

export const reportItems = (state: ReportItemState): OverlayReportItem => {
  return state.reportItems;
};

export const reportItemById =
  (state: ReportItemState) =>
  (id: string): OverlayReportItem => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.reportItems.hasOwnProperty(id)) {
      return state.reportItems[id];
    }
    return {};
  };

export const reportItemDetailsByReportItemId =
  (state: ReportItemState) =>
  (id: string): OverlayReportItem => {
    return get(state.reportItems, `[${id}].details`) || {};
  };

export const missingFilesByReportItemId =
  (state: ReportItemState) =>
  (id: string): OverlayReportItem => {
    return get(state.reportItems, `[${id}].missing_files`) || [];
  };

export const exceptionsByReportItemId =
  (state: ReportItemState) =>
  (id: string): OverlayReportItem => {
    return get(state.reportItems, `[${id}].exceptions`) || [];
  };

export const editorsByReportItemId =
  (state: ReportItemState) =>
  (id: string): OverlayReportItem => {
    const editors = get(state.reportItems, `[${id}].editors`) || [];
    return filter(editors, (e) => e.type !== 'xml.upload');
  };

export const validationErrorsByReportItemId =
  (state: ReportItemState) =>
  (id: string): OverlayReportItem => {
    const editors = get(state.reportItems, `[${id}].editors`) || [];
    return filter(editors, (e) => e.type === 'xml.upload');
  };

const elevatedDecisionTypes = ['decision-not-reportable'];
const elevatedDecisionIds = ['reportable-with-exception'];

export const decisionsByReportItemId =
  (state: ReportItemState) =>
  (id: string): OverlayReportItem => {
    return (
      filter(
        get(state.reportItems, `[${id}].decisions`),
        (decision: ReportItemDecision) =>
          !elevatedDecisionIds.includes(decision.id) &&
          !elevatedDecisionTypes.includes(decision.type),
      ) || []
    );
  };

export const decisionsElevatedByReportItemId =
  (state: ReportItemState) =>
  (id: string): OverlayReportItem => {
    return (
      filter(
        get(state.reportItems, `[${id}].decisions`),
        (decision: ReportItemDecision) =>
          elevatedDecisionIds.includes(decision.id) ||
          elevatedDecisionTypes.includes(decision.type),
      ) || []
    );
  };

export const statusByReportItemId =
  (state: ReportItemState) =>
  (id: string): string => {
    return get(state.reportItems, `[${id}].status`) || '';
  };

export const detailsByReportItemId =
  (state: ReportItemState) =>
  (id: string): OverlayReportItem => {
    return get(state.reportItems, `[${id}].details`) || [];
  };

export const readOnlyByReportItemId =
  (state: ReportItemState) =>
  (id: string): boolean => {
    return !!get(state.reportItems, `[${id}].read_only`);
  };
