import { OverlayState } from '../../store';
import { OPluginContext } from './types';

export const pluginContextSet = (
  state: OverlayState,
  context: OPluginContext,
): void => {
  state.pluginContext = context;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const userSessionSet = (state: OverlayState, userSession: any): void => {
  state.userSession = userSession;
};
