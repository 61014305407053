import { AxiosPromise } from 'axios';
import {
  ReportItem,
  ReportItemEditApiApprove,
  ReportItemEditApiReject,
  ExceptionIgnoreApiRequest,
  ExceptionIgnoreApiApprove,
  ExceptionIgnoreApiReject,
  NotReportableApiRequest,
  NotReportableApiApprove,
  NotReportableApiReject,
  ReportItemCheckRequest,
  ReportItemRulesRetry,
  ReportItemCheckResponse,
} from '@/service';
import {
  ReportItemContext,
  ReportItemEditRequest,
  ReportItemEditApprove,
  ReportItemEditReject,
  ExceptionIgnoreRequest,
  ExceptionIgnoreApprove,
  ExceptionIgnoreReject,
  NotReportableRequest,
  NotReportableApprove,
  NotReportableReject,
  NotReportableDownload,
  ReportItemCheckPayload,
  ReportEntryEditRequest,
  ReportEntryEditApprove,
  ReportEntryEditReject,
  ReportItemRulesRetryRequest,
} from './types';

import { downloadFile, extractFileName } from 'i8-ui';

// get available editors and decisions for a report item
export const reportItemGet = async (
  ctx: ReportItemContext,
  id: string,
): Promise<void> => {
  const response = await ReportItem.get(id);
  ctx.commit('reportItemSet', {
    payload: response.data,
    documentId: id,
  });
};

/* --- REPORT ITEM EDIT --- */

// Request edit
export const reportItemEditRequest = async (
  ctx: ReportItemContext,
  { reportItemId, edit }: ReportItemEditRequest,
): Promise<void> => {
  const response = await ReportItem.editRequest(reportItemId, edit);
  ctx.commit('reportItemSet', {
    payload: response.data,
    documentId: reportItemId,
  });
};

// Approve edit
export const reportItemEditApprove = async (
  ctx: ReportItemContext,
  { reportItemId, editId }: ReportItemEditApprove,
): Promise<void> => {
  // endpoint required a post body
  const payload: ReportItemEditApiApprove = {};
  const response = await ReportItem.editApprove(reportItemId, editId, payload);
  ctx.commit('reportItemSet', {
    payload: response.data,
    documentId: reportItemId,
  });
};

// Reject edit
export const reportItemEditReject = async (
  ctx: ReportItemContext,
  { reportItemId, editId, reason }: ReportItemEditReject,
): Promise<void> => {
  const payload: ReportItemEditApiReject = { reason };
  const response = await ReportItem.editReject(reportItemId, editId, payload);
  ctx.commit('reportItemSet', {
    payload: response.data,
    documentId: reportItemId,
  });
};

/* --- EXCEPTION IGNORE --- */

// Request exception ignore
export const exceptionIgnoreRequest = async (
  ctx: ReportItemContext,
  { reportItemId, exceptionId, justification }: ExceptionIgnoreRequest,
): Promise<void> => {
  const payload: ExceptionIgnoreApiRequest = { justification };
  const response = await ReportItem.exceptionIgnoreRequest(
    reportItemId,
    exceptionId,
    payload,
  );
  ctx.commit('reportItemSet', {
    payload: response.data,
    documentId: reportItemId,
  });
};

// Approve exception ignore
export const exceptionIgnoreApprove = async (
  ctx: ReportItemContext,
  { reportItemId, exceptionId }: ExceptionIgnoreApprove,
): Promise<void> => {
  // endpoint required a post body
  const payload: ExceptionIgnoreApiApprove = {};
  const response = await ReportItem.exceptionIgnoreApprove(
    reportItemId,
    exceptionId,
    payload,
  );
  ctx.commit('reportItemSet', {
    payload: response.data,
    documentId: reportItemId,
  });
};

// Reject exception ignore
export const exceptionIgnoreReject = async (
  ctx: ReportItemContext,
  { reportItemId, exceptionId, reason }: ExceptionIgnoreReject,
): Promise<void> => {
  const payload: ExceptionIgnoreApiReject = { reason };
  const response = await ReportItem.exceptionIgnoreReject(
    reportItemId,
    exceptionId,
    payload,
  );
  ctx.commit('reportItemSet', {
    payload: response.data,
    documentId: reportItemId,
  });
};

/* --- REPORT ITEM NOT REPORTABLE --- */

// Request not reportable
export const notReportableRequest = async (
  ctx: ReportItemContext,
  { reportItemId, justification, file, onUploadProgress }: NotReportableRequest,
): Promise<void> => {
  const payload: NotReportableApiRequest = { justification, file };
  const response = await ReportItem.notReportableRequest(
    reportItemId,
    payload,
    onUploadProgress as () => void,
  );
  ctx.commit('reportItemSet', {
    payload: response.data,
    documentId: reportItemId,
  });
};

// Not reportable approve
export const notReportableApprove = async (
  ctx: ReportItemContext,
  { reportItemId }: NotReportableApprove,
): Promise<void> => {
  // endpoint required a post body
  const payload: NotReportableApiApprove = {};
  const response = await ReportItem.notReportableApprove(reportItemId, payload);
  ctx.commit('reportItemSet', {
    payload: response.data,
    documentId: reportItemId,
  });
};

// Not reportable reject
export const notReportableReject = async (
  ctx: ReportItemContext,
  { reportItemId, reason }: NotReportableReject,
): Promise<void> => {
  const payload: NotReportableApiReject = { reason };
  const response = await ReportItem.notReportableReject(reportItemId, payload);
  ctx.commit('reportItemSet', {
    payload: response.data,
    documentId: reportItemId,
  });
};

export const notReportableDownload = async (
  ctx: ReportItemContext,
  { reportItemId }: NotReportableDownload,
): Promise<void> => {
  const response = await ReportItem.notReportableDownload(reportItemId);

  const filename = extractFileName(response);
  const mime = response.headers['content-type'];
  downloadFile(response.data, filename, mime);
};

/* --- EDIT CHECK --- */

// run a rule set against a report item with an array of edits
export const reportItemEditCheck = (
  ctx: ReportItemContext,
  { reportItemId, edits }: ReportItemCheckPayload,
): AxiosPromise<ReportItemCheckResponse> => {
  const payload: ReportItemCheckRequest = { edits };
  return ReportItem.check(reportItemId, payload);
};

/* --- REPORT ENTRY EDIT  --- */

// Request edit
export const reportEntryEditRequest = async (
  ctx: ReportItemContext,
  { reportItemId, edit }: ReportEntryEditRequest,
): Promise<void> => {
  const response = await ReportItem.reportEntryEditRequest(reportItemId, edit);
  ctx.commit('reportItemSet', {
    payload: response.data,
    documentId: reportItemId,
  });
};

// Approve edit
export const reportEntryEditApprove = async (
  ctx: ReportItemContext,
  { reportItemId, editId }: ReportEntryEditApprove,
): Promise<void> => {
  // endpoint required a post body
  const payload: ReportItemEditApiApprove = {};
  const response = await ReportItem.reportEntryEditApprove(
    reportItemId,
    editId,
    payload,
  );
  ctx.commit('reportItemSet', {
    payload: response.data,
    documentId: reportItemId,
  });
};

// Reject edit
export const reportEntryEditReject = async (
  ctx: ReportItemContext,
  { reportItemId, editId, reason }: ReportEntryEditReject,
): Promise<void> => {
  const payload: ReportItemEditApiReject = { reason };
  const response = await ReportItem.reportEntryEditReject(
    reportItemId,
    editId,
    payload,
  );
  ctx.commit('reportItemSet', {
    payload: response.data,
    documentId: reportItemId,
  });
};

/* --- RULE SET RETRY --- */

// Re-run a transaction through the reporting rules
export const reportItemRulesRetry = async (
  ctx: ReportItemContext,
  { reportItemId }: ReportItemRulesRetryRequest,
): Promise<ReportItemCheckResponse> => {
  // endpoint required a post body
  const payload: ReportItemRulesRetry = {};
  const response = await ReportItem.rulesRetry(reportItemId, payload);
  return response.data;
};
