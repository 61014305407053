/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosPromise } from 'axios';
import { Api } from '@/service/api.service';
import {
  SummaryReportItemRequest,
  SummaryReportItemMsgType,
  SummarySystemRequest,
  SummarySystemMsg,
} from './types';
import querystring from 'querystring';

const resource = '/summary';

export const Dashboard = {
  summaryReportItemGet(
    query: SummaryReportItemRequest,
    request: SummaryReportItemMsgType[],
  ): AxiosPromise<any> {
    return Api.post(
      `${resource}/report-item?${querystring.encode(query)}`,
      request,
    );
  },

  /**
   * Download a report item summary csv file
   *
   * @param query SummaryReportItemRequest
   */
  summaryReportItemDownload(
    query: SummaryReportItemRequest,
    request: SummaryReportItemMsgType[],
  ): AxiosPromise<any> {
    return Api.post(
      `${resource}/report-item/download?${querystring.encode(query)}`,
      request,
      {
        responseType: 'arraybuffer',
      },
    );
  },

  summarySystemGet(
    query: SummarySystemRequest,
    request: SummarySystemMsg,
  ): AxiosPromise<any> {
    return Api.post(`${resource}/system?${querystring.encode(query)}`, request);
  },
};
