import { DashboardState, ReportItemSummary, SystemSummary } from './types';

export const reportItemSummary =
  (state: DashboardState) =>
  (date: string): ReportItemSummary | undefined => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.reportItemSummary.hasOwnProperty(date)) {
      return state.reportItemSummary[date];
    }
  };

export const systemSummary =
  (state: DashboardState) =>
  (date: string): SystemSummary | undefined => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.reportItemSummary.hasOwnProperty(date)) {
      return state.reportItemSummary[date];
    }
  };
