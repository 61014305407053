import { Plugin as VuexPlugin, Store } from 'vuex';
import { Plugin } from '../../sdk/plugin';
import { OPluginContext } from './types';

export function OverlayVuexPlugin(
  // eslint-disable-next-line @typescript-eslint/ban-types
  modelFactory?: (store: Store<unknown>) => object,
): VuexPlugin<unknown> {
  return (store) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function dispatch<T = any>(type: string) {
      return (payload: T) => store.dispatch(type, payload);
    }

    const pluginModel = {
      setContext: dispatch<OPluginContext>('overlay/pluginContextUpdate'),
      userSessionSet: dispatch('overlay/userSessionSet'),
    };

    Plugin.connectToOverlay({
      ...(modelFactory && modelFactory(store)),
      ...pluginModel,
    });
  };
}
