





import Vue from 'vue';
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import { OPluginContext, OverlayUserSession } from 'overlay-plugin-sdk';
import { Api } from '@/service';

export default Vue.extend({
  name: 'App',

  computed: {
    ...mapGetters('overlay', ['pluginContext', 'userSession']),
  },

  watch: {
    userSession: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      handler(newSession: OverlayUserSession, oldSession: OverlayUserSession) {
        Api.authTokenSet(newSession.token);
      },
      immediate: true,
    },
    pluginContext: {
      handler(ctx: OPluginContext) {
        if (isEmpty(ctx) || ctx.type === undefined) {
          if (this.$route.name !== 'home') {
            void this.$router.replace({ name: 'home' });
          }
          return;
        }

        switch (ctx.type) {
          case 'document':
            // eslint-disable-next-line no-case-declarations
            const sameType = ctx.meta.type === this.$route.name;
            // eslint-disable-next-line no-case-declarations
            const sameId = ctx.meta.id === this.$route.params.id;

            if (sameType && sameId) {
              // not doing this will cause a NavigationDuplicated error
              return;
            }

            void this.$router
              .push({
                name: ctx.meta.type,
                params: { id: ctx.meta.id },
              })
              .catch();
            break;

          case 'document.list':
            // not doing this will cause a NavigationDuplicated error
            if (this.$route.name !== 'home') {
              void this.$router.replace({ name: 'home' });
            }
            break;

          default:
            console.warn('unknown plugin context type', ctx);

            // not doing this check will cause a NavigationDuplicated error
            if (this.$route.name !== 'home') {
              void this.$router.replace({ name: 'home' });
            }
            break;
        }
      },
      immediate: true,
    },
  },
});
