import { Schedule } from '@/service';
import {
  ScheduleContext,
  ScheduleRetryRequest,
  ScheduleIgnoreRequest,
} from './types';

/**
 * Retry a failed schedule Job
 *
 * @param ctx
 * @param payload
 */
export const scheduleRetry = async (
  ctx: ScheduleContext,
  { name, runTime }: ScheduleRetryRequest,
): Promise<void> => {
  if (!name) {
    console.warn('unable to retry schedule, name not found', name);
    return;
  }

  if (!runTime) {
    // runTime is the ID of a single job
    console.warn('unable to retry schedule, run time not found', runTime);
    return;
  }

  await Schedule.retry(name, runTime);
};

/**
 * Ignore a failed schedule Job
 *
 * @param ctx
 * @param payload
 */
export const scheduleIgnore = async (
  ctx: ScheduleContext,
  { name, runTime }: ScheduleIgnoreRequest,
): Promise<void> => {
  if (!name) {
    console.warn('unable to retry schedule, name not found', name);
    return;
  }

  if (!runTime) {
    // runTime is the ID of a single job
    console.warn('unable to ignore schedule, run time not found', runTime);
    return;
  }

  await Schedule.ignore(name, runTime);
};
