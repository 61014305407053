import Vue from 'vue';
import {
  DashboardState,
  ReportItemSummaryEntry,
  SystemSummaryEntry,
} from './types';

export const reportItemSummarySet = (
  state: DashboardState,
  { reportItemSummary, date }: ReportItemSummaryEntry,
): void => {
  Vue.set(state.reportItemSummary, date, reportItemSummary);
};

export const systemSummarySet = (
  state: DashboardState,
  { systemSummary, date }: SystemSummaryEntry,
): void => {
  Vue.set(state.systemSummary, date, systemSummary);
};
