import { Report, ReportOverrideAPIRequest } from '@/service';
import { ReportContext, ReportOverrideRequest } from './types';

import { downloadFile, extractFileName } from 'i8-ui';

export const reportGet = async (
  ctx: ReportContext,
  reportId: string,
): Promise<void> => {
  const response = await Report.get(reportId);
  ctx.commit('reportSet', {
    payload: response.data,
    documentId: reportId,
  });
};

export const reportDownload = async (
  ctx: ReportContext,
  id: string,
): Promise<void> => {
  const response = await Report.download(id);

  const filename = extractFileName(response);
  const mime = response.headers['content-type'];
  downloadFile(response.data, filename, mime);
};

// Report override
export const reportOverride = async (
  ctx: ReportContext,
  { reportId, report, onUploadProgress }: ReportOverrideRequest,
): Promise<void> => {
  const payload: ReportOverrideAPIRequest = { report };

  const response = await Report.override(reportId, payload, onUploadProgress);

  ctx.commit('reportSet', {
    payload: response.data,
    documentId: reportId,
  });
};

// Request not reportable
export const reportStats = async (
  ctx: ReportContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  query: any,
): Promise<void> => {
  const { data } = await Report.stats(query);
  return data;
};
