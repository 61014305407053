import { Api } from '@/service/api.service';
import querystring from 'querystring';
import { ExportQuery, ExportResponse } from './types';
import { AxiosPromise } from 'axios';

const resource = '/exports';

export const DataExport = {
  /**
   * Export a set of report items
   *
   * @param query
   */
  reportItem(query: ExportQuery): AxiosPromise<ExportResponse> {
    return Api.post(`${resource}/report-item?${querystring.encode(query)}`);
  },

  /**
   * Export a set of reports
   *
   * @param query
   */
  report(query: ExportQuery): AxiosPromise<ExportResponse> {
    return Api.post(`${resource}/report?${querystring.encode(query)}`);
  },

  /**
   * Export a set of transactions
   *
   * @param query
   */
  transaction(query: ExportQuery): AxiosPromise<ExportResponse> {
    return Api.post(`${resource}/transaction?${querystring.encode(query)}`);
  },
};
