import { Api } from '../service/api.service';
import { DocumentService, DocumentAPIQuery } from '../service';
import querystring from 'querystring';

const resource = '/documents';

export const Document = {
  // Get a single document by id
  getById(documentId: string) {
    return Api.get(`${resource}/${documentId}`);
  },

  // Get a list of documents by type
  getByType(query: DocumentAPIQuery) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Api.get(`${resource}?${(querystring as any).encode(query)}`);
  },

  // Get a single document by id
  getEvents(documentId: string) {
    return Api.get(`${resource}/${documentId}/events`);
  },
} as DocumentService;
