import { DocumentState, PagedDocuments, DocumentEvent } from './types';

export const documentsByTypeGet =
  (state: DocumentState) =>
  (documentType: string): PagedDocuments => {
    return state.documentsByType[documentType];
  };

export const documentByIdGet =
  (state: DocumentState) =>
  (
    documentId: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any => {
    return state.documentsById[documentId];
  };

export const documentEventsGet =
  (state: DocumentState) =>
  (documentId: string): DocumentEvent[] => {
    return state.documentEvents[documentId];
  };
