import { Module } from 'vuex';

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import { TransactionState } from './types';

export * from './types';

export const transaction: Module<TransactionState, unknown> = {
  namespaced: true,
  state: {
    transactions: [],
  } as TransactionState,
  actions,
  mutations,
  getters,
};

export type TransactionGetters = typeof getters;
export type TransactionActions = typeof actions;
export type TransactionMutations = typeof mutations;
