import { Module } from 'vuex';

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import { ConfigState } from './types';

export * from './types';

export const config: Module<ConfigState, unknown> = {
  namespaced: true,
  state: {
    name: '',
  } as ConfigState,
  mutations,
  actions,
  getters,
};

export type ConfigGetters = typeof getters;
export type ConfigActions = typeof actions;
export type ConfigMutations = typeof mutations;
