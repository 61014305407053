import { OverlayState } from '../../store';
import { OPluginContext, OverlayUserSession } from './types';

export const pluginContext = (state: OverlayState): OPluginContext => {
  return state.pluginContext;
};

export const userSession = (state: OverlayState): OverlayUserSession => {
  return state.userSession;
};
