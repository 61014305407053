import Vue from 'vue';
import Vuex from 'vuex';

import { config } from './config';
import { dashboard } from './dashboard';
import { dataExport } from './data-export';
import { importJob } from './import-job';
import { plugins } from './plugins';
import { report } from './report';
import { reportItem } from './report-item';
import { schedule } from './schedule';
import { transaction } from './transaction';

import { overlay, document } from 'overlay-plugin-sdk';

export * from './config';
export * from './dashboard';
export * from './data-export';
export * from './import-job';
export * from './report-item';
export * from './report';
export * from './schedule';
export * from './transaction';
export * from './types';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    config,
    dashboard,
    dataExport,
    document,
    importJob,
    overlay,
    report,
    reportItem,
    schedule,
    transaction,
  },
  plugins,
});
