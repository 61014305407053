import { Module } from 'vuex';

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import { OverlayState } from '../../store';

export const overlay: Module<OverlayState, unknown> = {
  namespaced: true,
  state: {
    pluginContext: {},
    userSession: {},
  } as OverlayState,
  actions,
  mutations,
  getters,
};

export type OverlayGetters = typeof getters;
export type OverlayActions = typeof actions;
export type OverlayMutations = typeof mutations;

export * from './types';
export * from './plugin';
