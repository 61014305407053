import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

export const router = new Router({
  // NOTE: abstract mode is required for IE11 compatibility when
  //       this plugin is used in an iframe alongside Overlay+ UI.
  //       Without it, the back button doesn't work correctly even when
  //       using $route.replace
  mode: 'abstract',

  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/screen/home/home.vue'),
    },
    {
      path: '/report-items/:id',
      name: 'identitii.payreg.reportitem.v1.ReportItem',
      component: () =>
        import(
          /* webpackChunkName: "report-item" */ '@/screen/report-item/report-item.vue'
        ),
    },

    {
      path: '/report-items/:id/edits/create',
      name: 'report-item.edit.create',
      component: () =>
        import(
          /* webpackChunkName: "report-item" */ '@/screen/report-item/edit-create.vue'
        ),
      props: true,
    },
    {
      path: '/report-items/:id/edits/reject',
      name: 'report-item.edit.reject',
      component: () =>
        import(
          /* webpackChunkName: "report-item" */ '@/screen/report-item/edit-reject.vue'
        ),
      props: true,
    },
    {
      path: '/report-items/:id/exception/:exceptionId/ignore',
      name: 'report-item.exception.ignore',
      component: () =>
        import(
          /* webpackChunkName: "report-item" */ '@/screen/report-item/exception-ignore-confirm.vue'
        ),
      props: true,
    },
    {
      path: '/report-items/:id/not-reportable',
      name: 'report-item.not-reportable',
      component: () =>
        import(
          /* webpackChunkName: "report-item" */ '@/screen/report-item/not-reportable-confirm.vue'
        ),
      props: true,
    },
    {
      path: '/report/:id',
      name: 'identitii.payreg.report.v1.Report',
      component: () =>
        import(/* webpackChunkName: "report" */ '@/screen/report/report.vue'),
    },
    {
      path: '*',
      name: '404',
      component: () => import('@/screen/error/404.vue'),
    },
  ],
});
