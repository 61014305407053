import { ImportJob, ImportJobCreateAPIRequest } from '@/service';
import { ImportJobContext, ImportJobCreateRequest } from './types';

// Import job create
export const importJobCreate = async (
  ctx: ImportJobContext,
  { file, schema, onUploadProgress }: ImportJobCreateRequest,
): Promise<void> => {
  const payload: ImportJobCreateAPIRequest = { file, schema };

  await ImportJob.create(payload, onUploadProgress);
};
